import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";

const Dashboards = lazy(() => import("../../Pages/Dashboards"));

const About = lazy(() => import("../../Pages/Samples/"));

const AppMain = () => {
  return (
    <Fragment>
    
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="loader-wrapper d-flex justify-content-center align-items-center">
                <Loader color="#726666" type="ball-zig-zag-deflect" />
              </div>
              <div className="text-center" style={{ color: "white" }}>
                Loading MauiArtPrints.Com <br />
              </div>
              <br />
            </div>
          </div>
        }
      >
        <Route path="/dashboards" component={Dashboards} />
        <Route path="/samples" component={About} />
      </Suspense>
      <Route exact path="/" render={() => <Redirect to="/dashboards/home" />} />
    </Fragment>
  );
};

export default AppMain;
