import React, { Fragment } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { withRouter } from "react-router-dom";

import ResizeDetector from "react-resize-detector";
import AppMain from "../../Layout/AppMain";
import AppHeader from "../../Layout/AppHeader";

import CheckVersions from "./checkVersions";
import packageJson from "../../meta.json";

import { unregister } from "../../serviceWorker";
var appVersion = packageJson.version;

import App from "../../Pages/Dashboards/Home/Examples/backgroundeffect";
import "firebase/storage";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/performance";

import firebase from "firebase/app";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closedSmallerSidebar: false,
    };
  }
  decideVersionCheck() {
    if (this.state.hasLoaded === 1) {
      return (<CheckVersions />), this.setState({ hasLoaded: 2 });
    } else {
      return null;
    }
  }

  componentDidMount() {
    const defaultAnalytics = firebase.analytics();
    firebase.performance();
    this.toggle1();
    window.addEventListener("hashchange", this.toggle1, false);

    document.body.addEventListener("click", async function (e) {
      const cityRef = firebase
        .firestore()
        .collection("totalClicks")
        .doc("value");

      try {
        await firebase.firestore().runTransaction(async (t) => {
          const doc = await t.get(cityRef);

          const newPopulation = doc.data().population + 1;
          t.update(cityRef, { population: newPopulation });
        });
      } catch (e) {
        console.log("Transaction failure:", e);
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener("hashchange", this.toggle1.bind(this), false);
  }
  async toggle1() {
    const cityRef = firebase.firestore().collection("totalHits").doc("value");

    try {
      await firebase.firestore().runTransaction(async (t) => {
        const doc = await t.get(cityRef);

        const newPopulation = doc.data().population + 1;
        t.update(cityRef, { population: newPopulation });
      });
    } catch (e) {
      console.log("Transaction failure:", e);
    }

    let concData = [];
    try {
      const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE,
        authDomain: "mauiartprints.firebaseapp.com",
        projectId: "mauiartprints",
        storageBucket: "mauiartprints.appspot.com",
        messagingSenderId: "976385059528",
        appId: "1:976385059528:web:5b934ff9014d7fc134d3fd",
        measurementId: "G-WVZRL7G536",
      };
      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
      }
    } catch (error) {}
  }

  openRightSidebar() {
    document.getElementById("ChatBlock").style.opacity = 1;
    document.getElementById("ChatBlock").hidden = false;
  }

  render() {
    if (appVersion) {
      if (!localStorage.getItem("appVersion")) {
        localStorage.setItem("appVersion", appVersion);
      }
    }
    this.decideVersionCheck();
    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
    } = this.props;

    return (
      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <Fragment>
            <App />
            <div
              ref={this.myRef}
              className={cx(
                "app-container app-theme-" + colorScheme,
                { "fixed-header": enableFixedHeader },
                { "fixed-sidebar": enableFixedSidebar || width < 1250 },
                { "fixed-footer": enableFixedFooter },
                { "closed-sidebar": enableClosedSidebar || width < 1250 },
                {
                  "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
                },
                { "sidebar-mobile-open": enableMobileMenu },
                { "body-tabs-shadow-btn": enablePageTabsAlt }
              )}
            >
              <AppHeader />
              <AppMain />
            </div>
          </Fragment>
        )}
      />
    );
  }
}

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withRouter(connect(mapStateToProp)(Main));
