import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./polyfills";


import "core-js";

import "core-js/features/set";

import React from "react";
import ReactDOM from "react-dom";

import { HashRouter } from "react-router-dom";
import "./App.scss";
import "./App.js";
import Main from "./Pages/Main";
import configureStore from "./config/configureStore";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const store = configureStore();
const rootElement = document.getElementById("root");

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <Component />
      </HashRouter>
    </Provider>,
    rootElement
  );
};
function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}
if (iOS) {
  serviceWorkerRegistration.unregister();
} else {
  serviceWorkerRegistration.register();
}
reportWebVitals();

renderApp(Main);
